import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const SlmTbImg = ({ name, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      tb00001: file(relativePath: { eq: "trusted-by-slm/tb00001.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1108) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data[name] ? (
    <Img
      fluid={data[name].childImageSharp.fluid}
      alt={alt || ""}
      imgStyle={{ objectFit: "contain" }}
    />
  ) : null
}

export default SlmTbImg
