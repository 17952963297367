import React, { Component } from "react"
import { Slide, Fade } from "react-slideshow-image"

import "../scss/silverlinemdm.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SlmTbImg from "../components/slm-tb-img"
import SolLogo from "../components/solution-logo"
import {
  navbarUpdate,
  setDefaultHeaderTheme,
  getSlmFeatures,
  getSlmTbContents,
} from "../utils/common-util"

const phoneWHRatio = 0.498

class SilverlineMDMPage extends Component {
  constructor(props) {
    super(props)

    this.topFeaRef = React.createRef()
    this.hwcInfo = React.createRef()
    this.phoneWrap = React.createRef()
    this.maxTop = 0

    this.trustedByImgRef = React.createRef()
    this.trustedBySliderProps = {
      // duration: 10000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: false,
      autoplay: false,
      onChange: this.onTrustedBySlideChange.bind(this),
    }
    this.trustedByFadeProp = {
      transitionDuration: 400,
      infinite: false,
      indicators: false,
      autoplay: false,
      arrows: false,
    }

    this.state = {
      currentScroll: 0,
      phoneLocked: true,
      phoneBluetooth: false,
      phoneBtNote: "",
      phoneCamera: false,
      phoneCamNote: "",
      phoneApp: false,
      phoneAppIcon: false,
      phoneAppNote: "",
      appProgress: 0,
    }
    this.topNoteAdded = false
    this.secondNoteAdded = false

    this.onPageScroll = this.onPageScroll.bind(this)

    this.togglePhoneBluetooth = this.togglePhoneBluetooth.bind(this)
    this.togglePhoneCamera = this.togglePhoneCamera.bind(this)
    this.togglePhoneLock = this.togglePhoneLock.bind(this)
    this.togglePhoneApp = this.togglePhoneApp.bind(this)
  }

  onTrustedBySlideChange = (oldIndex, newIndex) => {
    this.trustedByImgRef.current.goTo(newIndex)
  }

  onPageScroll = e => {
    let diff = window.scrollY - this.state.currentScroll
    let top = parseInt(this.phoneWrap.current.style.top)
    if (diff > 0) {
      top = top + diff
      if (top < 0) {
        this.phoneWrap.current.style.top = top + "px"
        this.setState({ currentScroll: window.scrollY })
      } else {
        this.phoneWrap.current.style.top = "0px"
        if (!this.hwcInfo.current.classList.contains("show-info")) {
          this.hwcInfo.current.classList.add("show-info")
          this.phoneWrap.current.parentElement.classList.add("has-phone")
          this.setState({
            phoneLocked: false,
          })
          this.setState({ currentScroll: window.scrollY })
        }
      }
    } else if (diff < 0) {
      top = top + diff
      if (top > this.maxTop) {
        this.phoneWrap.current.style.top = top + "px"
        this.setState({ currentScroll: window.scrollY })
        if (top < -20 && this.hwcInfo.current.classList.contains("show-info")) {
          this.hwcInfo.current.classList.remove("show-info")
          this.phoneWrap.current.parentElement.classList.remove("has-phone")
          this.setState({
            phoneLocked: true,
          })
        }
      } else {
        this.phoneWrap.current.style.top = this.maxTop + "px"
      }
    }
  }

  togglePhoneBluetooth = e => {
    this.showHideNote("bt")
    this.setState({
      phoneBtNote:
        "Bluetooth is " + (this.state.phoneBluetooth ? "disabled" : "enabled"),
      phoneBluetooth: !this.state.phoneBluetooth,
    })
  }

  togglePhoneCamera = e => {
    this.showHideNote("cam")
    this.setState({
      phoneCamNote:
        "Camera is " + (this.state.phoneCamera ? "disabled" : "enabled"),
      phoneCamera: !this.state.phoneCamera,
    })
  }

  togglePhoneLock = e => {
    this.setState({
      phoneLocked: !this.state.phoneLocked,
    })
  }

  togglePhoneApp = e => {
    this.showHideNote("app", true)
    this.setState({
      phoneAppNote:
        (this.state.phoneApp ? "Uninstalling" : "Installing") + " Foo App",
      phoneApp: !this.state.phoneApp,
    })
  }

  showHideNote = (type, progress) => {
    if (!this.phoneWrap.current.classList.contains("show-" + type + "-note")) {
      let cls = ["show-" + type + "-note"]
      if (!this.topNoteAdded) {
        cls.push(type + "-at-1st")
        this.topNoteAdded = true
      } else if (!this.secondNoteAdded) {
        cls.push(type + "-at-2nd")
        this.secondNoteAdded = true
      }
      this.phoneWrap.current.classList.add(...cls)
      if (progress) {
        setTimeout(() => {
          this.setState({
            phoneAppIcon: !this.state.phoneAppIcon,
          })
        }, 1800)
        progress = setInterval(() => {
          this.setState({
            appProgress: this.state.appProgress + 1,
          })
        }, 15)
      }
      setTimeout(() => {
        this.phoneWrap.current.classList.remove(...cls)
        this.topNoteAdded = cls.includes(type + "-at-1st")
          ? false
          : this.topNoteAdded
        this.secondNoteAdded = cls.includes(type + "-at-2nd")
          ? false
          : this.secondNoteAdded
        if (progress) {
          clearInterval(progress)
          this.setState({
            appProgress: 0,
          })
        }
      }, 2000)
    }
  }

  componentDidMount() {
    setDefaultHeaderTheme(true)

    this.setState({ currentScroll: 0 })

    this.phoneWrap.current.parentElement.style.height =
      "calc(100% - " + this.hwcInfo.current.clientHeight + "px)"

    let wrapHt = this.phoneWrap.current.parentElement.clientHeight - 30
    let wrapWd = wrapHt * phoneWHRatio
    let maxWd = this.phoneWrap.current.parentElement.clientWidth - 170
    let partVisible = this.topFeaRef.current.clientHeight / 3
    if (wrapWd >= maxWd) {
      wrapWd = maxWd
      wrapHt = wrapWd / phoneWHRatio
    }
    this.phoneWrap.current.style.height = wrapHt.toFixed(1) + "px"
    this.phoneWrap.current.style.width = wrapWd.toFixed(1) + "px"
    this.phoneWrap.current.style.left =
      "calc(50% - " + (wrapWd / 2).toFixed(1) + "px)"

    this.maxTop = -this.hwcInfo.current.clientHeight - partVisible
    this.phoneWrap.current.style.top = this.maxTop + "px"

    window.addEventListener("scroll", this.onPageScroll, false)

    navbarUpdate("nav-products")
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onPageScroll, false)
  }

  render() {
    const features = getSlmFeatures()
    const tbContents = getSlmTbContents()

    return (
      <Layout>
        <SEO title="Silverline MDM" />
        <div className="slm-top-content iww-full-height iww-container responsive">
          <div className="sol-icon">
            <SolLogo name="mdm" alt="Silverline MDM's Logo"></SolLogo>
          </div>
          <h1 className="top-header">
            Experience the Future of Device Management
          </h1>
          <div ref={this.topFeaRef} className="top-feature-map">
            <div className="feature bluetooth"></div>
            <div className="feature camera"></div>
            <div className="feature reboot"></div>
            <div className="feature lock"></div>
            <div className="feature install-uninstall"></div>
          </div>
        </div>
        <div className="hardware-controls iww-container">
          <div ref={this.hwcInfo} className="hwc-info">
            <div className="hwc-header">Configure. Control. Monitor.</div>
            <div className="hwc-desc">
              An intelligent solution for organisations to manage their mobile
              devices remotely. Amongst 97 Enterprise Mobility Management (EMM)
              approved by Google.
            </div>
          </div>
          <div className="dpf-wrap">
            <div className="dummy-func bluetooth-func">
              <input
                id="bluetooth-switch"
                type="checkbox"
                onChange={this.togglePhoneBluetooth}
                checked={this.state.phoneBluetooth}
                name="bluetoothSwitch"
                className="switch is-rounded"
              />
              <label htmlFor="bluetooth-switch" className="switch-rl">
                Bluetooth On
              </label>
              <label htmlFor="bluetooth-switch" className="switch-ll">
                Bluetooth Off
              </label>
            </div>
            <div className="dummy-func lock-func">
              <input
                id="lock-switch"
                type="checkbox"
                onChange={this.togglePhoneLock}
                checked={this.state.phoneLocked}
                name="lockSwitch"
                className="switch is-rounded"
              />
              <label htmlFor="lock-switch" className="switch-rl">
                Lock
              </label>
              <label htmlFor="lock-switch" className="switch-ll">
                Unlock
              </label>
            </div>
            <div
              ref={this.phoneWrap}
              className={
                "phone-wrap" + (this.state.phoneLocked ? " locked" : "")
              }
            >
              <div className="phone"></div>
              <div className="phone-bg">
                <div className="func-icons">
                  {this.state.phoneBluetooth ? (
                    <span>
                      <i className="fab fa-bluetooth-b"></i>
                    </span>
                  ) : null}
                  <span>
                    <i className="fas fa-battery-full"></i>
                  </span>
                </div>
                <div className="dummy-apps">
                  <div className="dummy-app">
                    <span className="app-icon">
                      <i className="fas fa-cog"></i>
                    </span>
                    <span className="app-name">Settings</span>
                  </div>
                  {this.state.phoneCamera ? (
                    <div className="dummy-app">
                      <span className="app-icon">
                        <i className="fas fa-camera"></i>
                      </span>
                      <span className="app-name">Camera</span>
                    </div>
                  ) : null}
                  {this.state.phoneAppIcon ? (
                    <div className="dummy-app">
                      <span className="app-icon">
                        <i className="fas fa-rocket"></i>
                      </span>
                      <span className="app-name">FooApp</span>
                    </div>
                  ) : null}
                </div>
                <span className="lock-icon">
                  <i className="fas fa-lock"></i>
                </span>
                <div className="phone-note bt-note">
                  <span className="note-icon">
                    <i className="fab fa-bluetooth-b"></i>
                  </span>
                  <div className="note-text">{this.state.phoneBtNote}</div>
                </div>
                <div className="phone-note cam-note">
                  <span className="note-icon">
                    <i className="fas fa-camera"></i>
                  </span>
                  <div className="note-text">{this.state.phoneCamNote}</div>
                </div>
                <div className="phone-note app-note">
                  <span className="note-icon">
                    <i className="fas fa-rocket"></i>
                  </span>
                  <div className="note-text">
                    <div>{this.state.phoneAppNote}</div>
                    <progress
                      className="progress is-info is-small"
                      value={this.state.appProgress}
                      max="100"
                    ></progress>
                  </div>
                </div>
              </div>
            </div>
            <div className="dummy-func camera-func">
              <input
                id="camera-switch"
                type="checkbox"
                onChange={this.togglePhoneCamera}
                checked={this.state.phoneCamera}
                name="cameraSwitch"
                className="switch is-rounded"
              />
              <label htmlFor="camera-switch" className="switch-rl">
                Camera On
              </label>
              <label htmlFor="camera-switch" className="switch-ll">
                Camera Off
              </label>
            </div>
            <div className="dummy-func install-func">
              <input
                id="install-switch"
                type="checkbox"
                onChange={this.togglePhoneApp}
                checked={this.state.phoneApp}
                name="installSwitch"
                className="switch is-rounded"
              />
              <label htmlFor="install-switch" className="switch-rl">
                Install
              </label>
              <label htmlFor="install-switch" className="switch-ll">
                Uninstall
              </label>
            </div>
          </div>
        </div>
        <div className="its-easy iww-container">
          <div className="its-easy-header">Product Features</div>
          {/* <div className="its-easy-desc">Apart from the fact that it is amongst 97 Enterprise Mobility Management (EMM) solutions approved by Google globally; here’s a sneak peak into the benefits of using MDM.</div> */}
          <div className="slm-features">
            {features.map(fea => {
              return (
                <div key={fea.key} className="slm-feature">
                  <div className={"slm-feature-ind " + fea.icon}></div>
                  <div className="slm-feature-title">{fea.label}</div>
                  <div className="slm-feature-desc">{fea.desc}</div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="slm-trusted-by-content">
          <div className="trusted-by-imgs">
            <Fade {...this.trustedByFadeProp} ref={this.trustedByImgRef}>
              {tbContents.map(tb => {
                return (
                  <div key={tb.key + "-img"} className="each-fade">
                    <SlmTbImg name={tb.key}></SlmTbImg>
                  </div>
                )
              })}
            </Fade>
          </div>
          <div className="trusted-by-slides">
            <div className="trusted-by-title">
              <span className="left-line"></span>
              <span>Trusted By</span>
            </div>
            <Slide {...this.trustedBySliderProps}>
              {tbContents.map(tb => {
                return (
                  <div key={tb.key + "-cnt"} className="each-slide">
                    <div className="trusted-by-head">{tb.label}</div>
                    <div className="trusted-by-text">{tb.desc}</div>
                  </div>
                )
              })}
            </Slide>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SilverlineMDMPage
